/* cyrillic-ext */
@font-face {
  font-family: '__Shantell_Sans_0992cd';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/3c6c9f42ca55c5f4.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Shantell_Sans_0992cd';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/36f1d06048b136ed.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Shantell_Sans_0992cd';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/c714d44fe7af6761.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Shantell_Sans_0992cd';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/64376e0cec647356.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Shantell_Sans_0992cd';
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/345e142937404b1d.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}.__className_0992cd {font-family: '__Shantell_Sans_0992cd', Helvetica Neue, Helvetica, Arial, sans-serif;font-style: normal
}.__variable_0992cd {--handwritten-base-font-family: '__Shantell_Sans_0992cd', Helvetica Neue, Helvetica, Arial, sans-serif
}

